import React from "react";
import styles from "../CSS/contact.module.css";

const contact = () => (
	<section className={styles.section} id="contact">
		<h2 className={styles.h2}>Contact Us</h2>
		<p className={styles.p}>
			To partner with us, request our services, or for more information, call:{" "}
			<a className={styles.contact} href="tel:+234-916-793-0610">
				+234 (0)916 793 0610
			</a>

			<a className={styles.contact} href="tel:+234-807-388-3547">
				+234 (0)807 388 3547
			</a>

			<span className={styles.or}>OR</span> email us at:{" "}
			<a
				className={styles.contact}
				rel="noopener noreferrer"
				target="_blank"
				href="mailto:connect@manamuz.com"
			>
				connect@manamuz.com
			</a>
		</p>
	</section>
);

export default contact;
