import React from "react";

import styles from "../CSS/each_service_detail.module.css";

const minigrids = () => (
	<div id="minigrids" className={styles.service_detail}>
		<h2>Minigrids</h2>
		<p>
			Mini grid, also sometimes referred to as a "micro grid or isolated
			grid", is an off-grid electricity distribution network involving
			small-scale electricity generation. They are power sources with
			typical capacities that range from 10KW to 10MW which serves a
			limited number of consumers via a distribution grid that can operate
			in isolation from national electricity transmission networks." The
			size of this grid depends on the population size in question. The
			power source may be a diesel-powered generator, a renewable energy
			power plant or a hybrid power plant.
		</p>
		<p>
			Some locations, such as remote islands, are often omitted from
			government electrification initiatives. In these cases, mini grids
			represent the only option. But properly designed mini grid solutions
			have a clear advantage over individual stand-alone home energy
			systems in terms of service quality and supply.
		</p>
		<p>
			Mini-grids are functional at small scale and as potential future
			connections to a larger grid, making this technology attractive for
			ensuring the reliability of future energy systems. In many areas,
			centralized power is not feasible due to relatively small dispersed
			loads in remote areas, but a mini-grid provides an attractive
			alternative with enhanced stability when compared to stand- alone
			systems.
		</p>
		<p>
			Furthermore, transmissions remain an issue for geographically
			isolated areas, making off-grid alternatives necessary in certain
			situations. Considering the large distances transmission lines must
			cover to reach rural areas from centralized generation, up to 30% of
			power can be lost before reaching the destination, significantly
			decreasing the efficiency of the overall electrical system. As such,
			localized mini-grids with on-site generation provide a reasonable
			alternative.
		</p>
		<p>
			Mini grids have been used for the electrification of remote areas
			for at least two decades. But they remain a marginal option.
			Investments in mini grid projects are still limited compared to
			those in grid extension projects. However, the International Energy
			Agency (IEA) estimates that mini grids will be the most economical
			way of delivering more than 40 percent of the installed capacity
			required en route to achieving universal access to electricity by
			2030.
		</p>
		<h3>Distribution</h3>
		<p>
			A mini-grid distribution system carries the energy produced by the
			generation source to the end users. It consists of transmission
			lines, transformer, and the infrastructure necessary to enable safe
			and effective energy distribution. Depending on the load
			requirements, a distribution system can be in AC or DC singe or
			three-phase power. AC has many benefits, as it allows for effective
			electricity transmission over distances, meets the requirements for
			consumer appliances, and is more widely used. However, AC also
			requires transformers to decrease high voltage distribution network
			costs and decrease system loss, but is also generally more expensive
			than DC because of the enhanced power electronics.
		</p>
		<h3>Benefits</h3>
		<p>
			There are many potential benefits have been observed with mini-grid
			systems ranging from technical and environmental to social and
			financial advantages. They include but not limited to the following:
		</p>
		<ul>
			<li>
				The technology used in mini-grids provides various benefits.
				Mini-grids are relatively quick and easy to implement in areas
				without electricity. They can also be used to improve existing
				electrical grids that are ineffective or unreliable by providing
				additional power or by replacing them completely.
			</li>
			<li>
				Most hybrid electrical systems contain at least some redundancy,
				but mini-grids are organized to prevent this and enhance
				productive.
			</li>
			<li>
				Mini-grids are also more efficient because they can provide a
				low load at night when less electricity is needed. Unlike
				conventional energy generation, mini-grids reduce the energy
				lost at night time when less energy is required by the
				community. Larger electrical systems such as diesel generator
				cannot offer this because they are inefficient at low loads and
				most often continue operating at higher loads regardless of the
				amount of electricity needed.
			</li>
			<li>
				An additional benefit mini-grids provide is that they do not
				require a traditional fuel source as many larger scale electric
				grids do. This means they can be easily implemented in areas
				without access to diesel or other fossil fuels. This reduces
				operating costs and reliance on often fluctuating fuel prices.
			</li>
			<li>
				Mini-grids also require less maintenance than larger electrical
				grids. Since they reduce the hours that diesel generators are
				used at low loads, generators last longer and do not need to be
				replaced as often. Because of the rural areas where mini-grids
				are typically used, there is often little access to supplies or
				technicians if system maintenance is needed.
			</li>
			<li>
				Mini-grids are also able to spread electrical storage across
				many users which reduces the cost when compared to off-grid or
				solar home systems where electrical storage is concentrated in
				one area.
			</li>
			<li>
				Mini-grids are also more profitable than other types of electric
				grids. Due to their improved electrical services and decreased
				malfunctions such as blackouts, customers are more satisfied
				overall, and thus willing to pay for the services mini-grids
				provide, leading to an increase in revenue.
			</li>
			<li>
				Mini-grids are much more environmentally friendly than other
				types of grids. Since they reduce the need for diesel
				generators, greenhouse gas emissions are greatly reduced. This
				also improves air and noise pollution in the areas mini-grids
				are used.
			</li>
			<li>
				Mini-grids provide the necessary services for businesses to
				succeed in developing areas. This leads to the creation of more
				jobs and an increase in income for the community. Improved
				electricity can also benefit healthcare technology and
				institutions in the areas and lead to a higher standard of
				living.
			</li>
		</ul>
		<h3>Challenges</h3>
		<p>Common challenges for the implementation of mini-grids include:</p>
		<ol>
			<li>
				Lack of maintenance or the use of poor quality or untested
				technology. This could be as a lack of sufficient funding to
				sustain the project over its lifetime or the shortage of local
				skills for maintenance of the mini-grid.
			</li>
			<li>
				Poor assessment that is mostly compounded by lack of data on the
				local physical parameters that affect the power output and
				economics including:
				<ul>
					<li>
						Population density within the location of the mini-grid,
						This will have an influence on the load factor as well
						as the overall economics of the mini-grid.
					</li>
					<li>
						Type of terrain, this will have an impact on the various
						resources required for the mini-grid i.e. the cost of
						infrastructure, fuel cost (for diesel systems) as well
						as the operation and financing of the system.
					</li>
					<li>
						Seasonal resource fluctuations- This includes solar
						isolation, wind speed fluctuations and river flows.
					</li>
				</ul>
			</li>
			<li>
				Development of schemes without attention to developing
				supplementary programes dealing with issues such as market
				access, small medium enterprise (SME) development and working
				with local financing institutions, has contributed to a lack of
				demand and inability to sustain the schemes.
			</li>
		</ol>
	</div>
);

export default minigrids;
