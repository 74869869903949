import React from "react";
import styles from "../CSS/meet_the_team.module.css";
import Uzor from "../../../assets/uzor.cc37d029.jpg";
import Jude from "../../../assets/Engr.Jude.jpg";
import miracle from "../../../assets/miracle_new.jpeg";
import Charles from "../../../assets/munachi.5fa873ce.jpg";
import Constance from "../../../assets/constance_new.jpg";
import Sandra from "../../../assets/sandra.jpg";
import Emeka from "../../../assets/emeka_new.jpg";


const meetTheTeam = () => (
	<section className={styles.section}>
		<h1>Meet the team</h1>
		<div className={styles.members}>
			<div className={styles.each}>
				<img src={Uzor} alt="CEO Uzochukwu Mbamalu" />
				<span className={styles.name}>
					Uzochukwu Mbamalu <br />
					<span className={styles.title}>CEO</span>
				</span>
				
				{/* <span className={styles.qualification}>
					B.Eng. Electronics Engineering, UNN
				</span> */}
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/uzombamalu/"
				>
					Profile
				</a>
			</div>



			<div className={styles.each}>
				<img alt="CTO Charles Aliozo" src={Charles} />
				<span className={styles.name}>
					Charles Aliozo <br />
					<span className={styles.title}>Technical Director</span>
				</span>
				{/* <span className={styles.qualification}>
					B.Eng. Electrical Engineering, UNN
				</span> */}
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/aliozocharles/"
				>
					Profile
				</a>
			</div>


			<div className={styles.each}>
				<img src={miracle} alt="Company Secretary, Miracle Ebere" />
				<span className={styles.name}>
					Miracle Ebere <br />
					<span className={styles.title}>COO</span>
				</span>
				{/* <span className={styles.qualification}>
					HND, Chemical Engineering, IMT, Enugu
				</span> */}
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/miracle-ebere-/"
				>
					Profile
				</a>
			</div>


			<div className={styles.each}>
				<img src={Jude} alt="engr jude" />
				<span className={styles.name}>
					Okoagu Jude <br />
					<span className={styles.title}>Head of Projects</span>
				</span>
				{/* <span className={styles.qualification}>
					HND, Engineering, IMT, Enugu
				</span> */}
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/okoagu-jude-kenechukwu-65601a140/"
				>
					Profile
				</a>
			</div>


			<div className={styles.each}>
				<img src={Constance} alt="Constance" />
				<span className={styles.name}>
					Constance Iwu <br />
					<span className={styles.title}>Head of Digital Transformation</span>
				</span>
				{/* <span className={styles.qualification}>
					HND, Chemical Engineering, IMT, Enugu
				</span> */}
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/constanceiwu/"
				>
					Profile
				</a>
			</div>


			<div className={styles.each}>
				<img src={Sandra} alt="sandra" />
				<span className={styles.name}>
					Sandra Anene <br />
					<span className={styles.title}>Account Officer</span>
				</span>
				{/* <span className={styles.qualification}>
					HND, Chemical Engineering, IMT, Enugu
				</span> */}
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/sandra-anene-23ba96245/"
				>
					Profile
				</a>
			</div>


			
		</div>
	</section>
);

export default meetTheTeam;
