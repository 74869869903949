import React from "react";
import styles from "../CSS/meet_the_team.module.css";
import Robyn from "../../../assets/robyn_new.jpeg";
import Dave from "../../../assets/dave.png";
import Emeka from "../../../assets/emeka_new.jpg";


const consult = () => (
	<section className={styles.section}>
		<h1>Technical Consultants</h1>

		<div className={styles.members}>

			<div className={styles.each}>
				<img src={Dave} alt="dave" />
				<span className={styles.name}>
					Prof. Dave Croasdell <br />
					<span className={styles.title}>Consultant, Digital Transformation and Knowledge Management</span>
				</span>
				{/* <span className={styles.qualification}>
					HND, Engineering, IMT, Enugu
				</span> */}
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/dave-croasdell-585843/"
				>
					Profile
				</a>
			</div>


			<div className={styles.each}>
				<img src={Robyn} alt="robyn" />
				<span className={styles.name}>
					Robyn Brunscher <br />
					<span className={styles.title}>Consultant, Supply Chain Design and Management</span>
				</span>
				{/* <span className={styles.qualification}>
					HND, Engineering, IMT, Enugu
				</span> */}
				<a
					rel="noopener noreferrer"
					href="https://www.linkedin.com/in/robyn-brunscher-6666001a/"
				>
				    Profile
				</a>
			</div>


			<div className={styles.each}>
				<img src={Emeka} alt="emeka" />
				<span className={styles.name}>
					Prof. Emeka Simon Obe <br />
					<span className={styles.title}>Consultant, Efficient Energy Systems and Engineering Innovation</span>
				</span>
				{/* <span className={styles.qualification}>
					HND, Engineering, IMT, Enugu
				</span> */}
				<a
					rel="noopener noreferrer"
					href="https://scholar.google.com/citations?user=j5QU96QAAAAJ&hl=en"
				>
					Profile
				</a>
			</div>


			
		</div>
	</section>
);

export default consult;
