import React from "react";
import { Link } from "react-router-dom";

import styles from "../CSS/social_impact.module.css";

const socialImpact = () => (
	<section className={styles.section}>
		<p className={styles.sub_title}>SOCIAL IMPACT</p>
		<h2>AgriBusiness Productivity Service</h2>
		<p className={styles.main}>
			We believe that it is not sustainable to invest in infrastructure without investing in people. 
			Hence, When we deploy energy and Agri tech solutions in rural agro communities,
			 we also empower the rural farmers with Agribusiness support services so that they can extract maximum value from the infrastructure provided.
		</p>
		<Link to="/social_impact">
			<button type="button" className={styles.cta}>
				Learn more
			</button>
		</Link>
	</section>
);

export default socialImpact;
