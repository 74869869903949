import React from "react";
import { Link } from "react-router-dom";
import battery from "../../../assets/solar-energy.a1ac0cda.png";
import light_bulb from "../../../assets/light_bulb.png";
import power_line from "../../../assets/power_line.png";
import save_energy from "../../../assets/cold-room.137a1fe8.png";
import solar_panel from "../../../assets/harvest.b3b6faca.png";
import water_energy from "../../../assets/tractor.158529a2.png";

//style
import styles from "../CSS/services.module.css";

const services = () => (
	<section className={styles.section}>
		<h2 className={styles.h2}>We are thinking beyond the national grid.</h2>
		<p className={styles.main_paragraph}>
			There is a promise of a very different future that relies heavily on
			Renewable energy and it can happen here.
		</p>

		<div className={styles.services}>
			<div className={styles.card}>
				<img
					className={styles.img}
					src={battery}
					alt="solar powered house"
				/>
				<h4>Solar PV Technology</h4>
				<p>
					Solar PV solutions for agro processing plants, 
					rural agro communities and other critical sectors of the agriculture value chain.
				</p>
				<Link
					className={styles.link}
					to="/services/#solar-pv-technology"
				>
					Learn more
				</Link>
			</div>


			<hr />
			<div className={styles.card}>
				<img
					className={styles.img}
					src={water_energy}
					alt="water energy"
				/>
				<h4>Agtech Products</h4>
				<p>
					Electrified and energy efficient Agricultural equipment for production, 
					processing and supply chain operations.
				</p>
				<Link className={styles.link} to="/services/#agric-tech-products">
					Learn more
				</Link>
			</div>


			<hr />
			<div className={styles.card}>
				<img
					className={styles.img}
					src={save_energy}
					alt="energy efficiency"
				/>
				<h4>Coldbox Store</h4>
				<p>
					Third party cold chain logistics for storage and 
					transport of perishable Agricultural Products.
				</p>
				<Link className={styles.link} to="/services/#cold-box-store">
					Learn more
				</Link>
			</div>


			<hr />
			<div className={styles.card}>
				<img
					className={styles.img}
					src={solar_panel}
					alt="solar panel"
				/>
				<h4>Post Harvest Grid</h4>
				<p>
					Agro produce management platform for connecting 
					smallholder farmers to wholesale and retail markets.
				</p>
				<Link
					className={styles.link}
					to="/services/#post-harvest-grid"
				>
					Learn more
				</Link>
			</div>


			{/* <hr />
			<div className={styles.card}>
				<img className={styles.img} src={light_bulb} alt="light bulb" />
				<h4>Electrical Installations</h4>
				<p>
					We deploy Electrical wiring and installations correctly
					while observing relevant electrical codes.
				</p>
				<Link
					className={styles.link}
					to="/services/#electrical-installations"
				>
					Learn more
				</Link>
			</div>


			<hr />
			<div className={styles.card}>
				<img className={styles.img} src={power_line} alt="power line" />
				<h4>Minigrids</h4>
				<p>
					We design and deploy offgrid and interconnected minigrids
					for communities with no or little energy access.
				</p>
				<Link className={styles.link} to="/services/#minigrids">
					Learn more
				</Link>
			</div> */}
		</div>
	</section>
);

export default services;
