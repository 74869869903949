import React from "react";
import styles from "../CSS/partners.module.css"; 
import allon from "../../../assets/pat_allon2.png";
import elev from "../../../assets/pat_elev.png";
import grid from "../../../assets/pat_grid.png";
import ieee from "../../../assets/pat_ieee.png";
import ises from "../../../assets/pat_ises.png";
import oxfam from "../../../assets/pat_oxfam.png";
import rmi from "../../../assets/pat_rmi.png";
import se4all from "../../../assets/pat_se4all.jpeg";
import usadf from "../../../assets/pat_usadf.png";
import geapp from "../../../assets/pat_geapp.jpeg";



const Partners = () => (
	<section className={styles.supportedBySection}>
	  <h2>Supported By</h2>
	  <div className={styles.logosContainer}>
		{[allon, elev, grid, ieee, ises, oxfam, rmi, se4all, usadf, geapp].map(
		  (logo, index) => (
			<div key={index} className={styles.logoItem}>
			  <img src={logo} alt={`Partner ${index + 1}`} />
			</div>
		  )
		)}
	  </div>
	</section>
  );
  
  export default Partners;


