import React from "react";

import styles from "../CSS/each_service_detail.module.css";

const solarBoreholes = () => (
	<div id="solar-boreholes" className={styles.service_detail}>
		<h2>Ag Tech Products</h2>
		<p>
			We develop and electrify Agtech products for the Agricultural value chain. Presently, 
			our focus is on the perishable food supply chain where farmers lose up to 55% of their harvest 
			and productivity is a major challenge.
		</p>

		<p>
			To this end, we have developed energy-efficient cold storage and 
			transport infrastructure for perishable food supply chains. 
			These are powered by solar and are what the Coldbox Store is built upon. 
			The Cold storage solution which can be described as a refrigerated warehouse is 
			customized to meet the requirements of the farmers it was built for. 
			The volume ranges from around 10 cubic meters up to 100 cubic meters and 
			the temperature can go as low as -40 degrees Centigrade.
		</p>

		<p>
			We have also developed a mobile chicken house for raising poultry on pasture. 
			This mobile chicken house which we have named the Gofman Coop can raise up to 800 birds at a time. 
			Also, it helps farmers improve the biodiversity and quality of the meat they produce.
		</p>
		
		<p>
			Currently, we are developing processing products for 
			other value chains such as oil palm, maize, and cassava as 
			these are products that will be added to our post-harvest grid.
		</p>
	</div>
);

export default solarBoreholes;
