import React from "react";

import styles from "../CSS/each_service_detail.module.css";

const energyEfficiency = () => (
	<div id="energy-efficiency" className={styles.service_detail}>
		<h2>Coldbox Store</h2>
		<p>
			We finance, produce, develop, and operate commercial 
			solar-powered cold storage and transport systems. 
			These can be integrated at every stage of the perishable 
			food supply chain to eliminate post-harvest losses. 
			They are operated using a third-party cold chain logistics model. 
			The commercial cold storage solutions are deployed primarily in farm 
			clusters and markets where they act as storage and distribution centers.
		</p>

		<p>
			Reliable cold chains that begin at the first mile increases
			the shelf life of fruits and vegetable such as the tomato from 
			two days up to three weeks and increases the shelf life of 
			freshly cut meat from a few hours up to one year. 
			These reliable cold chains are what we are building, and it is called the Coldbox Store.
		</p>

		<p>
			The Coldbox Store is a network of off-grid solar-powered 
			Refrigerated warehouse solutions that are deployed at the first mile. 
			It allows us to leverage sustainable cold chain technology 
			in the improvement of food security and elimination of post-harvest 
			losses without further damaging the planet as the warehouses are powered by solar for sustainability.
		</p>

		<p>
			The Coldbox store captures value from harvests that could have been lost. 
			(Up to 40 billion dollars in value is lost annually in Nigeria) and 
			is uniquely positioned to deliver climate-smart agriculture that achieves 
			the triple win of enhanced resilience, increased productivity, and 
			reduced emissions in the perishable food value chain.
		</p>
		
		<p>
			We ensure affordability and adoption of Solar Refrigerated 
			warehouses by building and operating them such that farmers 
			and Agribusinesses can just pay for cooling services rendered 
			without buying the refrigeration system.
		</p>

		<p>
			For more details about our distributed network of cold storage solutions, 
			visit <a href="https://coldboxstore.com/">Coldbox Store</a>.
		</p>


	</div>
);

export default energyEfficiency;
