import React, { Component } from "react";
import Layout from "../../Layout/JS/layout";
import Banner from "../../../components/Homepage/JS/banner";
import Services from "../../../components/Homepage/JS/services";
import SocialImpact from "../../../components/Homepage/JS/social_impact";
import Partners from "../../../components/Homepage/JS/partners";
import ContactUs from "../../../components/Homepage/JS/contact";
import Footer from "../../../components/Layout/JS/footer";

class Homepage extends Component {
	render() {
		return (
			<Layout>
				<Banner />
				<Services />
				<SocialImpact />
				<Partners />
				<ContactUs />
				<Footer />
			</Layout>
		);
	}
}

export default Homepage;
