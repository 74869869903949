import React, { Component } from "react";
import Layout from "../../Layout/JS/layout";
import AboutCompany from "../../../components/AboutUs/JS/about_the_company";
import MeetTheTeam from "../../../components/AboutUs/JS/meet_the_team";
import Consultant from "../../../components/AboutUs/JS/consultant";
import Footer from "../../../components/Layout/JS/footer";

class AboutUs extends Component {
	componentDidMount() {
		document.title = "Manamuz Electric | About Us";
	}

	render() {
		return (
			<Layout>
				<AboutCompany />
				<MeetTheTeam />
				<Consultant/>
				<Footer />
			</Layout>
		);
	}
}

export default AboutUs;
