import React from "react";

import styles from "../CSS/each_service_detail.module.css";

const solarPoweredBuildings = () => (
	<div id="solar-powered-buildings" className={styles.service_detail}>
		<h2>Solar PV Technology</h2>
		<p>
			We develop and implement off-grid solar systems, hybrid solar systems, 
			Grid consumption Reduction systems (Grid-connected solar), solar mini-grids, 
			solar boreholes, energy storage systems, and other clean energy systems.
		</p>

		<p>
			We currently finance, develop and operate solar projects in the 
			Agricultural value chain while we provide Engineering, procurement, 
			construction, and management in other sectors.
		</p>


		<p>
			Our decision to prioritize Sustainable Energy for the Agricultural sector is 
			influenced by our knowledge that electricity is critical for boosting the 
			productivity of everybody in the food value chain starting from the farmer to the restaurant, 
			food processor, and even the final consumer. For instance, 
			electricity costs account for up to 90% of the operational costs of convention cold rooms, 
			and these costs are transferred to the food customer. Those who can’t afford these costs don’t 
			bother cooling their produce and lose up to 50% of the value.
		</p>

		<p>
			This is a problem we know we can fix with solar and energy efficiency, 
			so we are doing it. Our strategy is to understand the electricity requirements 
			of critical players in the food value chain and develop the best approach to 
			provide them with a reliable solar plant.
		</p>

		<p>
			This could be through a Minigrid, power purchase agreement, 
			a lease-to-own arrangement, direct purchase, or solar-powered appliance financing.
		</p>
	
	</div>
);

export default solarPoweredBuildings;
