import React from "react";
import styles from "../CSS/q_a.module.css";

const questionsAndAnswers = () => (
	<main className={styles.main}>
		<h1>Frequently Asked Questions</h1>
		<div className={styles.each}>
			<p className={styles.questions}>
				Q1 – Apart from cold storage, what other Agtech solutions can you develop?
			</p>
			<hr />
			<p className={styles.answer}>
				A1 – We have a dedicated Research and development laboratory 
				that builds products that are required to support the Agricultural sector. 
				These products can be grain mills, vegetable dryers, oil palm mills, cassava mils, 
				electric mobility, mobile coops, and others.
			</p>
		</div>


		<div className={styles.each}>
			<p className={styles.questions}>
				Q2 – Can I power my factory with solar?
			</p>
			<hr />
			<p className={styles.answer}>
				A2 – Yes, we can deploy commercial and industrial solar 
				plants for your factory. Also, we can offer 
				financing depending on if your factory meets certain requirements.
			</p>
		</div>


		<div className={styles.each}>
			<p className={styles.questions}>Q3 – Do you Still develop solar plants for residential and non-Agricultural applications?</p>
			<hr />
			<p className={styles.answer}>
				A3 – Yes, we do. However, if it requires financing, 
				it would be done in collaboration with our partners Cloud Energy 
				Photoelectric LTD (Nigeria), or any other partners whose financing terms suit your needs.
			</p>
		</div>


		<div className={styles.each}>
			<p className={styles.questions}>
				Q4 – I own a food business; how can we partner?
			</p>
			<hr />
			<p className={styles.answer}>
				A4 – You can rely on our logistics infrastructure to get 
				farm supplies for your restaurant. We can also help you deploy efficient 
				cold storage at your restaurant and power your restaurant with solar.
			</p>
		</div>


		<div className={styles.each}>
			<p className={styles.questions}>
				Q5 – I own a farm. How can we partner?
			</p>
			<hr />
			<p className={styles.answer}>
				A5 – You can rent our storage and processing facilities. We can also build one for you.
			</p>
		</div>


		
	</main>
);

export default questionsAndAnswers;
