import React from "react";
import styles from "../CSS/gigawatt_project.module.css";

const gigawattProject = () => (
	<main className={styles.main}>
		<h1>Agribusiness Support Service</h1>
		<p>
			We believe that it is not sustainable to invest in infrastructure without investing in people. 
			Hence, when we deploy energy and Agri tech solutions in rural communities, 
			we also empower the rural farmers with Agribusiness support services so that they can extract maximum value from the infrastructure provided.
		</p>
	
		<p>
			This service is designed to support farmers in boosting their productivity, 
			adopting modern technologies as well as meeting the quality, grade, and safety requirements of target markets. 
			This service enables the African farmer to meet relevant high standards before harvests 
			are introduced into a cold chain that maintains this standard. 
			Beneficiaries of this service are given access to relevant information, training, and fund-raising support.
		</p>
		<p>
			This service promotes and strengthens clusters of out-grower cooperatives while 
			empowering more women and youths to be involved in the value chain. 
			By offering this service, we Influence the Sustainable Intensification of Agricultural Production 
			and we are building value chains from the ground up with outputs that meet 
			the requirements of processors and food businesses. This is how we ensure consistency in the quantity and 
			quality of fresh produce available for urban and high-value markets.
		</p>

		
	</main>
);

export default gigawattProject;

